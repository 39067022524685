import ProfileSectionContainer from "../../../Components/ProfileSectionContainer/ProfileSectionContainer";

import { TStudent } from "../../../Services";
import Svgs from "Assets/Svgs";

type StudentProfileTypes = {
  student?: TStudent;
};

const StudentProfile = ({ student }: StudentProfileTypes) => {
  return (
    <>
      <ProfileSectionContainer
        header="Student profile"
        paragraph="View detailed profile information about a student"
      >
        <div className="flex gap-x-7">
          <div className="w-[80px] h-[80px] bg-gray-200 rounded-full">
            <img
              src={student?.profile_image || ""}
              alt="User"
              className="w-full h-full object-cover rounded-full"
            />
          </div>

          <div>
            <h2 className="text-xl font-medium mb-3">
              {student?.first_name} {student?.last_name}
            </h2>

            <div className="flex mb-5">
              <div className="text-[#737373] font-medium flex gap-x-2 items-center min-w-[200px]">
                <Svgs.UserIcon />
                <span>{student?.gender}</span>
              </div>

              <div className="flex items-center gap-x-2">
                <Svgs.AcademicIcon />
                <span className="text-[#737373] font-medium">
                  Undergraduate
                </span>
              </div>
            </div>

            <div className="flex mb-5">
              <div className="text-[#737373] font-medium flex gap-x-2 items-center min-w-[200px]">
                <Svgs.BuildingIcon />
                <span>{student?.employement_status}</span>
              </div>

              <div className="flex items-center gap-x-2">
                <Svgs.LocationPinIcon />
                <span className="text-[#737373] font-medium">
                  {student?.state}, {student?.country}
                </span>
              </div>
            </div>

            <div className="flex">
              <div className="text-[#737373] font-medium flex gap-x-2 items-center">
                <Svgs.EmailIcon />
                <span>{student?.email}</span>
              </div>
            </div>
          </div>
        </div>
      </ProfileSectionContainer>
    </>
  );
};

export default StudentProfile;
