import { ReactComponent as UserIcon } from "./user-icon.svg";
import { ReactComponent as AcademicIcon } from "./academic-icon.svg";
import { ReactComponent as EmailIcon } from "./email-icon.svg";
import { ReactComponent as BuildingIcon } from "./building-icon.svg";
import { ReactComponent as LocationPinIcon } from "./location-pin-icon.svg";

const Svgs = {
  UserIcon,
  AcademicIcon,
  EmailIcon,
  BuildingIcon,
  LocationPinIcon,
};

export default Svgs;
