import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { SetURLSearchParams } from "react-router-dom";
import { getMyProfile, login } from "Services";
import errors from "Utilities/errors";

export type requestType = {
  isLoading: boolean;
  data: null | any;
  error: null | any;
  id?: string;
};

type AuthUserContextValueType = {
  userLoginInfo: {
    email: string | null;
    password: string | null;
  };
  setUserLoginInfo: Dispatch<
    SetStateAction<{
      email: string | null;
      password: string | null;
    }>
  >;
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
  signInRequest: requestType;
  signIn: () => void;
  logout: () => void;
};

type AuthUserContextProviderTypes = {
  children: React.ReactNode;
};

export const AuthUserContext = createContext<AuthUserContextValueType>(
  {} as AuthUserContextValueType
);

const AuthUserContextProvider = ({
  children,
}: AuthUserContextProviderTypes) => {
  // States
  const [userLoginInfo, setUserLoginInfo] = useState<{
    email: string | null;
    password: string | null;
  }>({
    email: null,
    password: null,
  });

  // Query Params
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [signInRequest, setSignInRequest] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  //   Utils
  const redirectRoute = location.state || "/dashboard";
  const accessToken = localStorage.getItem("iseAdminAccessToken");

  const signIn = () => {
    setSignInRequest({ isLoading: true, data: null, error: null });

    if (userLoginInfo.email && userLoginInfo.password)
      login({
        email: userLoginInfo.email,
        password: userLoginInfo.password,
      })
        .then((res) => {
          localStorage.setItem("iseAdminAccessToken", res.data?.accessToken);
          localStorage.setItem("iseAdminRefreshToken", res.data?.refreshToken);

          setSignInRequest({
            data: res.data.admin,
            error: null,
            isLoading: false,
          });

          navigate(redirectRoute);
        })
        .catch((err) => {
          localStorage.setItem(
            "ise-admin-email",
            userLoginInfo.email as string
          );

          setSignInRequest({
            data: null,
            error: errors.format(err),
            isLoading: false,
          });

          if (
            err?.response?.data?.responseMessage ===
            "Email Verification Required!"
          ) {
            navigate("/verify-email");
          }
        });
  };

  const logout = () => {
    localStorage.removeItem("iseAdminAccessToken");
    localStorage.removeItem("iseAdminRefreshToken");

    navigate("/sign-in", { state: location.pathname });
  };

  // Effects
  useEffect(() => {
    if (accessToken) {
      setSignInRequest((state) => ({ ...state, isLoading: true }));
      getMyProfile()
        .then((res) =>
          setSignInRequest((state) => ({ ...state, data: res.data }))
        )
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setSignInRequest((state) => ({ ...state, isLoading: false }));
        });
    }

    // eslint-disable-next-line
  }, []);

  return (
    <AuthUserContext.Provider
      value={{
        userLoginInfo,
        setUserLoginInfo,
        searchParams,
        setSearchParams,
        signInRequest,
        signIn,
        logout,
      }}
    >
      {children}
    </AuthUserContext.Provider>
  );
};

export default AuthUserContextProvider;
