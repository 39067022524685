import { useRef, useState, useEffect } from "react";
import classes from "./SchoolCourseCard.module.css";
import ellipse from "../../Assets/Images/ellipses.svg";
import ActionsModal from "./ActionsModal/ActionsModal";
import { useNavigate, useParams } from "react-router-dom";
import { deleteCourseById, toggleCourseStatusById } from "Services";
import { useApp } from "Context/AppContext";
import { mutate } from "swr";
import endpoints from "Services/endpoints";
import errors from "Utilities/errors";

type SchoolCourseCardProps = {
  id: string | number;
  image: string;
  title: string;
  description: string;
  cohortNumber?: number | string;
  slug: string;
  isActive: boolean;
};

const SchoolCourseCard = ({
  id,
  image,
  title,
  description,
  cohortNumber,
  slug,
  isActive,
}: SchoolCourseCardProps) => {
  const navigate = useNavigate();
  const { schoolSlug } = useParams();
  const { setNotifications } = useApp();

  // State
  const [showOptions, setShowOptions] = useState(false);

  // Refs
  const containerRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const closeOptions = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node) &&
      optionsRef.current &&
      !optionsRef.current.contains(event.target as Node)
    ) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOptions);
    return () => {
      document.removeEventListener("mousedown", closeOptions);
    };
  }, []);

  const handleDeleteCourse = async () => {
    try {
      await deleteCourseById(id.toString());

      setNotifications([
        {
          title: "Successfully deleted course",
          severity: "success",
          id: Date.now(),
        },
      ]);

      mutate(endpoints.GET_SCHOOL_COURSES_BY_SLUG(schoolSlug || ""));
    } catch (err) {
      const errorMessage = errors.format(err);
      setNotifications([
        {
          title: errorMessage,
          severity: "error",
          id: Date.now(),
        },
      ]);
    }
  };

  const handleToggleStatus = async () => {
    try {
      await toggleCourseStatusById(id.toString());

      mutate(endpoints.GET_SCHOOL_COURSES_BY_SLUG(schoolSlug || ""));

      setNotifications([
        {
          title: "Successfully changed course status",
          severity: "success",
          id: Date.now(),
        },
      ]);
    } catch (err) {
      const errorMessage = errors.format(err);

      setNotifications([
        {
          title: errorMessage,
          severity: "error",
          id: Date.now(),
        },
      ]);
    }
  };

  return (
    <>
      <div className={classes.school}>
        <img src={image} alt={title} />
        <div>
          <h4>{title}</h4>
          <div className={classes.schoolInfo}>
            <div>
              <span>Total cohort: </span> <span>{cohortNumber}</span>
            </div>
            <p>{description}</p>
          </div>
        </div>
        <div
          ref={containerRef}
          className={classes.ellipse}
          onClick={toggleOptions}
        >
          <img src={ellipse} alt="more options" />
        </div>
        <div>
          {showOptions && (
            <div className={classes.popover} ref={optionsRef}>
              <ActionsModal
                isActive={isActive}
                onClick={() => {
                  navigate(`/courses/${schoolSlug}/${slug}/view`);
                }}
                onClick2={() => {
                  navigate(`/courses/${schoolSlug}/${slug}`);
                }}
                onClick3={() => {
                  navigate(`/courses/${id}/cohorts/__create`);
                }}
                onClick4={() => {
                  navigate(`/courses/${id}/cohorts`);
                }}
                onClick5={handleDeleteCourse}
                onClick6={handleToggleStatus}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SchoolCourseCard;
