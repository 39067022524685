import { TCohort, TCourse } from "Services";

import Button from "Components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";

interface IProps {
  isLoading: boolean;
  course: Partial<TCourse>;
  cohort: Partial<TCohort>;
}

function convertFileToImageURL(file: string | File) {
  if (!file) return "";

  if (typeof file === "string") {
    return file;
  }

  // Create an object URL for the file
  return URL.createObjectURL(file);
}

const CohortDetails = (props: IProps) => {
  const { course, cohort } = props;
  const { courseId, cohortId } = useParams();
  const navigate = useNavigate();

  return (
    <div>
      <section className="mb-8">
        <h4 className="text-[#737373] mb-2.5 text-[13px]">Name of course</h4>

        <p>{course?.name || ""}</p>
      </section>

      <section className="mb-8">
        <h4 className="text-[#737373] mb-2.5 text-[13px]">
          Course difficulty level
        </h4>

        <p>{course?.difficulty_level || ""}</p>
      </section>

      <section className="mb-8">
        <h4 className="text-[#737373] mb-2.5 text-[13px]">Course objectives</h4>

        {(
          course?.course_objective as { id: number | string; value: string }[]
        )?.map((obj) => (
          <p className="mb-2">{obj.value}</p>
        ))}
      </section>

      <section className="mb-8">
        <h4 className="text-[#737373] mb-2.5 text-[13px]">Course image</h4>

        <div className="h-[500px] w-[500px]">
          <img
            src={convertFileToImageURL(course.cover_image || "")}
            alt="school-image"
            className="h-full w-full object-cover"
          />
        </div>
      </section>

      <section className="mb-8">
        <h4 className="text-[#737373] mb-2.5 text-[13px]">
          Course description
        </h4>

        <p>{course?.description || ""}</p>
      </section>

      <section className="mb-8">
        <h4 className="text-[#737373] mb-2.5 text-[13px]">Who should enrol</h4>

        {course?.target_audiences?.map((audience) => (
          <div className="mb-5">
            <p className="text-lg mb-2">{audience?.category || ""}</p>
            <p className="text-[#737373] text-base">
              {audience?.description || ""}
            </p>
          </div>
        ))}
      </section>

      <section>
        <h3 className="font-medium mb-2.5 text-lg">Cohort information</h3>
        <hr />

        <div className="flex flex-wrap pt-8 gap-y-8">
          <div className="lg:w-1/3">
            <h4 className="text-[#737373] mb-2.5 text-[13px]">Cohort name</h4>

            <p>{cohort?.name || ""}</p>
          </div>

          <div className="lg:w-1/3">
            <h4 className="text-[#737373] mb-2.5 text-[13px]">
              Learning commitment
            </h4>

            <p>{cohort?.commitment || ""}</p>
          </div>

          <div className="lg:w-1/3">
            <h4 className="text-[#737373] mb-2.5 text-[13px]">
              Application deadline
            </h4>

            <p>{cohort?.application_deadline || ""}</p>
          </div>

          <div className="lg:w-1/3">
            <h4 className="text-[#737373] mb-2.5 text-[13px]">Start date</h4>

            <p>{cohort?.start_date || ""}</p>
          </div>

          <div className="lg:w-1/3">
            <h4 className="text-[#737373] mb-2.5 text-[13px]">
              Cohort duration
            </h4>

            <p>{cohort?.duration || ""}</p>
          </div>

          <div className="lg:w-1/3">
            <h4 className="text-[#737373] mb-2.5 text-[13px]">
              Course location
            </h4>

            <p>{cohort?.location || ""}</p>
          </div>

          <div className="lg:w-1/3">
            <h4 className="text-[#737373] mb-2.5 text-[13px]">
              Course certification
            </h4>

            <p>{cohort?.certification === true ? "Yes" : "No"}</p>
          </div>

          <div className="lg:w-1/3">
            <h4 className="text-[#737373] mb-2.5 text-[13px]">Cohort tutor</h4>

            <p>
              {cohort?.tutor?.first_name || ""} {cohort.tutor?.last_name || ""}
            </p>
          </div>

          <div className="lg:w-1/3">
            <h4 className="text-[#737373] mb-2.5 text-[13px]">
              Course full price
            </h4>

            <p>₦{cohort?.price || 0}</p>
          </div>
        </div>
      </section>

      <div className="flex items-center justify-end border-t border-[#D8D8D8] pt-6 mt-10">
        <div className="w-[187px]">
          <Button
            type="secondary"
            onClick={() => navigate(`/courses/${courseId}/cohorts/${cohortId}`)}
          >
            Edit Information
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CohortDetails;
