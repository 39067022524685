import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import breadcrumbsBack from "../../../Assets/Images/breadcrumbsBack.svg";
import Breadcrumbs from "../../../Components/Breadcrumbs/Breadcrumbs";
import StudentDetailsModules from "../StudentDetailsModules/StudentDetailsModules";
import Loader from "../../../Components/Loader/Loader";
import { useStudentById } from "Services/Students/Hooks";

const StudentDetailsMain = () => {
  // Router
  const { StudentId } = useParams();

  // Requests
  const { isLoading, data } = useStudentById(StudentId || null);
  const [student, setStudent] = useState(data?.data);

  const breadCrumbs = {
    image: breadcrumbsBack,
    array: [
      {
        title: "Back to students",
        route: "/users/students",
      },
      {
        title: `${student?.first_name || "None"} ${
          student?.last_name || "None"
        }`,
        route: `/users/students/${student?.id}`,
      },
    ],
  };

  useEffect(() => {
    if (data?.data) {
      setStudent(data?.data);
    }
  }, [data?.data]);

  if (isLoading) return <Loader />;

  return (
    <>
      <Breadcrumbs {...breadCrumbs} />
      <StudentDetailsModules student={student} />
    </>
  );
};

export default StudentDetailsMain;
