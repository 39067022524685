import {
  TrashIcon,
  EyeIcon,
  PlusIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";

import classes from "./ActionsModal.module.css";
import Switch from "Components/Switch";

type ActionsModalProps = {
  isActive: boolean;
  onClick: () => void;
  onClick2: () => void;
  onClick3: () => void;
  onClick4: () => void;
  onClick5: () => void;
  onClick6: (value: boolean) => void;
};

const ActionsModal = ({
  onClick,
  onClick2,
  onClick3,
  onClick4,
  onClick5,
  onClick6,
  isActive,
}: ActionsModalProps) => {
  const modalOptions = [
    {
      action: onClick,
      title: "View course",
      svg: <EyeIcon className="size-4 text-[#2E2E2E]" />,
    },
    {
      action: onClick2,
      title: "Edit course",
      svg: <PencilIcon className="size-4 text-[#2E2E2E]" />,
    },
    {
      action: onClick3,
      title: "Add cohort",
      svg: <PlusIcon className="size-4 text-[#2E2E2E]" />,
    },
    {
      action: onClick4,
      title: "View cohort",
      svg: <EyeIcon className="size-4 text-[#2E2E2E]" />,
    },
    {
      action: onClick5,
      title: "Delete course",
      svg: <TrashIcon className="size-4 text-[#2E2E2E]" />,
    },
  ];

  return (
    <div className={classes.container}>
      {modalOptions.map((data, i) => (
        <>
          <div key={i} onClick={data.action}>
            {data.svg}
            <span>{data.title}</span>
          </div>
        </>
      ))}

      <div className="border-t pt-5">
        <Switch value={isActive} onChange={onClick6} />

        <span>{isActive ? "Deactivate course" : "Activate course"}</span>
      </div>
    </div>
  );
};

export default ActionsModal;
