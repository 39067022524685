import useSWR from "swr";

import { fetcher, TStudent } from "Services";
import endpoints from "Services/endpoints";

export const useStudentById = (id: string | null) => {
  return useSWR(
    !id ? null : endpoints.GET_STUDENT_PROFILE(id),
    fetcher<TStudent>
  );
};
