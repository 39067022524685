import { ChangeEvent, useEffect, useState } from "react";
import {
  createCohort,
  getCohortById,
  getTutors,
  TCohort,
  TTutor,
  updateCohortById,
} from "Services";
import Button from "Components/Button/Button";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Input from "Components/Input/Input";
import DropdownWithSearch from "Components/DropdownWithSearch/DropdownWithSearch";
import errors from "Utilities/errors";
import { useApp } from "Context/AppContext";
import endpoints from "Services/endpoints";
import { format } from "date-fns";

const getTutorId = (value: string) => {
  if (!value) return null;
  return Number(value.split("-")[0]);
};

const Cohort = () => {
  const [cohort, setCohort] = useState<Partial<TCohort>>();
  const { cohortId, courseId } = useParams();
  const [searchParams] = useSearchParams();
  const { setNotifications } = useApp();

  const [tutors, setTutors] = useState<TTutor[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const mode = searchParams.get("mode");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;

    setCohort((state) => ({ ...state, [target.name]: target.value }));
  };

  useEffect(() => {
    getTutors()
      .then((res) => setTutors(res.data.data))
      .catch((err) => {
        const errorMessage = errors.format(err);

        setNotifications([
          { title: errorMessage, severity: "error", id: Date.now() },
        ]);
      });
  }, []);

  useEffect(() => {
    if (cohortId !== "__create") {
      getCohortById(endpoints.GET_COHORT_BY_ID(cohortId || "")).then((res) =>
        setCohort({
          ...res.data,
          full_price: res.data.price,
          tutor_id: `${res.data.tutor?.id} - ${res.data.tutor?.first_name} ${res.data.tutor?.last_name}`,
        })
      );
    }
  }, []);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      if (cohortId === "__create") {
        // create
        await createCohort(courseId?.toString() || "", {
          ...cohort,
          tutor_id: getTutorId(cohort?.tutor_id?.toString() || ""),
          full_price: Number(cohort?.full_price),
          committment: cohort?.commitment,
          certification: cohort?.certification === "yes",
        });

        setNotifications([
          {
            title: "Successfully created course cohort",
            severity: "success",
            id: Date.now(),
          },
        ]);

        navigate(`/courses/${courseId}/cohorts`);
        return;
      }

      await updateCohortById(cohortId?.toString() || "", {
        ...cohort,
        tutor_id: getTutorId(cohort?.tutor_id?.toString() || ""),
        full_price: Number(cohort?.full_price),
        committment: cohort?.commitment,
        certification: cohort?.certification === "yes",
      });

      setNotifications([
        {
          title: "Successfully updated course cohort",
          severity: "success",
          id: Date.now(),
        },
      ]);

      setTimeout(() => {
        navigate(`/courses/${courseId}/cohorts`);
      }, 1000);
    } catch (err) {
      const errorMessage = errors.format(err);

      setNotifications([
        { title: errorMessage, severity: "error", id: Date.now() },
      ]);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-white w-[940px] ml-6 mt-6 py-7 px-7 rounded-[10px] mb-10">
      <h2 className="text-[31px] font-medium mb-6">
        {cohortId === "__create"
          ? "Create a cohort"
          : mode
          ? "View cohort"
          : "Edit cohort"}
      </h2>

      <Input
        isRequired
        label="Cohort name"
        name="name"
        value={cohort?.name || ""}
        placeholder="E.g., Aspiring web developer"
        onChange={handleChange}
      />

      <Input
        isRequired
        label="Learning commitment"
        name="commitment"
        value={cohort?.commitment || ""}
        placeholder="E.g., Aspiring web developer"
        onChange={handleChange}
      />

      <Input
        type="date"
        isRequired
        label="Cohort application deadline"
        name="application_deadline"
        value={
          cohort?.application_deadline
            ? format(new Date(cohort.application_deadline), "yyyy-MM-dd")
            : ""
        }
        placeholder="E.g., Aspiring web developer"
        onChange={handleChange}
      />

      <Input
        type="date"
        isRequired
        label="Cohort start date"
        name="start_date"
        value={cohort?.start_date || ""}
        placeholder="E.g., Aspiring web developer"
        onChange={handleChange}
      />

      <DropdownWithSearch
        title="Select option"
        label="Cohort duration"
        selected={cohort?.duration}
        setSelected={(value) =>
          setCohort((state) => ({ ...state, duration: value }))
        }
        options={[
          "1 month",
          "2 month",
          "3 month",
          "4 month",
          "5 month",
          "6 month",
        ]}
      />

      <DropdownWithSearch
        title="Select option"
        label="Cohort location"
        selected={cohort?.location}
        setSelected={(value) =>
          setCohort((state) => ({ ...state, location: value }))
        }
        options={["virtual", "physical"]}
      />

      <DropdownWithSearch
        title="Select option"
        label="Course certification"
        selected={cohort?.certification ? "yes" : "no"}
        setSelected={(value) =>
          setCohort((state) => ({ ...state, certification: value }))
        }
        options={["yes", "no"]}
      />

      <DropdownWithSearch
        title="Select option"
        label="Assign a tutor to this cohort"
        selected={cohort?.tutor_id}
        setSelected={(value) =>
          setCohort((state) => ({ ...state, tutor_id: value }))
        }
        options={tutors.map(
          (tutor) => `${tutor.id} - ${tutor.first_name} ${tutor.last_name}`
        )}
      />

      <Input
        isRequired
        label="Enter course full price"
        name="full_price"
        value={cohort?.full_price?.toString() || ""}
        placeholder="E.g., Aspiring web developer"
        onChange={handleChange}
      />

      {!mode && (
        <div className="flex items-center justify-between border-t border-[#D8D8D8] pt-6 mt-10">
          <div className="flex gap-x-6">
            <div className="w-[101px]">
              <Button
                type="secondary"
                onClick={() => {
                  navigate(`/courses/${courseId}/cohorts`);
                }}
              >
                Back
              </Button>
            </div>

            <div>
              <Button
                onClick={handleSubmit}
                disabled={false}
                loading={isSubmitting}
              >
                {cohortId === "__create" ? "Create cohort" : "Update cohort"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cohort;
