import { StyledSwitch } from "./Switch.styles";

interface IProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
}

const Switch = ({ value, onChange, disabled }: IProps) => {
  return (
    <StyledSwitch>
      <label className="switch">
        <input
          type="checkbox"
          onChange={() => onChange && onChange(!value)}
          checked={value}
          disabled={disabled}
        />

        <span className="slider round"></span>
      </label>
    </StyledSwitch>
  );
};
export default Switch;
