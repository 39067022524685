import Layout from "Components/Layout/Layout";
import CourseDetails from "../Components/CohortDetails";
import useSWR from "swr";
import endpoints from "Services/endpoints";
import {
  fetcher,
  getCourseBySlug,
  TCohort,
  TCourse,
  TCourseObjective,
} from "Services";
import { useParams } from "react-router-dom";

const getCourseObjectives = (data: string): TCourseObjective[] | [] => {
  if (!data) return [];

  let parsedData = JSON.parse(data);

  if (typeof parsedData === "string") {
    // parsedData = JSON.parse(parsedData);
    return getCourseObjectives(parsedData);
  }

  return parsedData;
};

const ViewCohort = () => {
  const { courseId, cohortId } = useParams();

  const { isLoading, data: response } = useSWR(
    endpoints.GET_COURSE_BY_ID(courseId || ""),
    fetcher<TCourse>
  );

  const { isLoading: loadingCohort, data: cohortResponse } = useSWR(
    endpoints.GET_COHORT_BY_ID(cohortId || ""),
    fetcher<TCohort>
  );

  console.log("==========>>>", cohortResponse?.data);

  const course = {
    ...response?.data,
    course_objective: getCourseObjectives(
      (response?.data.course_objective as string) || ""
    ),
  };

  return (
    <Layout>
      <div className="bg-white w-[940px] ml-6 mt-6 py-7 px-7 rounded-[10px] mb-10">
        <h2 className="text-[31px] font-medium mb-6">
          View Course Information
        </h2>

        <CourseDetails
          isLoading={isLoading}
          course={course}
          cohort={cohortResponse?.data || {}}
        />
      </div>
    </Layout>
  );
};

export default ViewCohort;
